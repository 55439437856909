import { useTranslation } from "@pancakeswap/localization";
import { useIsMounted } from "@pancakeswap/hooks";
import { PropsWithChildren, ReactNode } from "react";
import { AutoColumn, RowBetween, Text, TextProps } from "../../components";

type SwapInfoType = {
  price: ReactNode;
  allowedSlippage: number;
  showSlip?: boolean;
};

export const SwapInfoLabel = (props: PropsWithChildren<TextProps>) => (
  <Text fontSize="12px" bold color="secondary" {...props} />
);

export const SwapInfo = ({ allowedSlippage, price, showSlip = true }: SwapInfoType) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  return (
    <AutoColumn gap="sm" py="0" mb="8px">
      <RowBetween alignItems="center">{price}</RowBetween>
      {showSlip && (
        <RowBetween alignItems="center">
          <SwapInfoLabel>{t("Slippage Tolerance")}</SwapInfoLabel>
          {isMounted && (
            <Text fontSize="16px" fontWeight={600} color="#B1B4BD">
              {allowedSlippage / 100}%
            </Text>
          )}
        </RowBetween>
      )}
    </AutoColumn>
  );
};
