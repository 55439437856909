import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} viewBox="0 0 72 72" fill="none" {...props}>
      <g filter="url(#filter0_f_8_1486)">
        <path d="M52 36c0 8.837-7.163 16-16 16s-16-7.163-16-16 7.163-16 16-16 16 7.163 16 16z" fill="#182325" />
      </g>
      <path
        d="M36.667 30l-5.334-6m0 0L26 30m5.333-6v24m8-6l5.334 6m0 0L50 42m-5.333 6V24"
        stroke="#27D880"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_f_8_1486"
          x={0}
          y={0}
          width={72}
          height={72}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation={10} result="effect1_foregroundBlur_8_1486" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
